<template>
	<div class="grid grid-cols-1 gap-6 lg:grid-cols-2">
		<slot name="header" />
		<div v-if="!$slots.header" class="text-center lg:text-left">
			<p class="font-jakarta text-xl font-medium text-white/80">Esta com dúvidas?</p>
			<h1 class="font-jakarta font-extrabold text-3xl lg:text-6xl text-white">Perguntas frequentes</h1>
		</div>
		<div>
			<Accordion :type="accordionType" class="w-full" collapsible>
				<AccordionItem
					v-for="item in faqItems"
					:key="item.title"
					:value="item.title"
					:class="mergedAccordionItemClass"
				>
					<AccordionTrigger :class="mergedAccordionTriggerClass">{{ item.title }}</AccordionTrigger>
					<AccordionContent class="font-jakarta text-white/80">
						{{ item.content }}
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</div>
	</div>
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

const props = defineProps<{
	accordionItemClass?: string
	accordionTriggerClass?: string
	accordionType?: 'single' | 'multiple'
	items?: Array<{ title: string; content: string }>
}>()

const faqItems = props.items || [
	{
		title: 'Quais as formas de pagamento?',
		content:
			'É possível pagar com cartão de crédito e boleto bancário. Aceitamos as bandeiras Visa, Mastercard, Elo, American Express, Diners Club, Hipercard e Discover.',
	},
	{
		title: 'Qual o prazo de entrega?',
		content:
			'O prazo de entrega varia de acordo com o tipo de frete escolhido. O prazo de produção é de 5 a 10 dias úteis.',
	},
	{
		title: 'Quais tamanhos de camiseta tem disponíveis?',
		content:
			'Temos camisetas nos tamanhos P, M, G e GG. Caso você precise de um tamanho diferente, entre em contato conosco.',
	},
	{
		title: 'Como posso participar do StreamersClub?',
		content:
			'É só clicar no botão "Quero participar" e preencher o formulário de inscrição. Iremos entrar em contato com você.',
	},
]

const accordionType = props.accordionType || 'single'

const mergedAccordionItemClass = computed(() => {
	return `border-b-[#293111] ${props.accordionItemClass}`
})
const mergedAccordionTriggerClass = computed(() => {
	return twMerge('text-white font-bold font-jakarta text-left gap-4', props.accordionTriggerClass)
})
</script>
